// src/App.js
import React from 'react';
import Right from './Right';

import Header from './Header';
import Features from './Features';
import Home from './Home';
import AboutUs from './Aboutus';

import Footer from './Footer';

import'bootstrap/dist/css/bootstrap.min.css'
import './Style.css'; // You can import a component-specific CSS file here if needed


const App = () => {
  return (
    <div>
    
      <Home/>
      <Header/>
      <main>
      

        <Features />
        <AboutUs />
        
        
    
      </main>
      <Footer />
      <Right/>
    </div>
  );
};
export default App;
