// src/components/BusinessTaxes.js
import React from 'react';
import './Style.css';
import Header from './Header';
import Footer from './Footer';
import Right from './Right';

const BusinessTaxes = () => {
  return (
    <div>
      <Header/>
      <section className="business-tax">
        <h2> Business Tax Preparations</h2>
        
      
      <a href="ContactPage" >
        <button className="transparent-button">Learn More</button>
        </a>
      </section>
      <section className="bustax">
      <p> Every business, regardless of its model, purpose, or number of employees, is obligated to submit accurate tax forms. Whether you own an S Corp, LLC, Partnership, or operate as a Sole Proprietorship, our business tax preparation services are tailored to meet your needs. Filing incorrect tax forms can result in fines or legal issues with the IRS. Ensure the accuracy of your business's taxes by engaging El Paso Business Solutions. We offer small business tax preparation services for business owners in and around El Paso, TX. Regardless of your business type, we are dedicated to developing a tax strategy that guarantees an accurate return.</p>
      </section>
      <section className="contact-section">
        <p>We'll help file your taxes quarterly and ensure no mistakes are made. You can call 915-231-6746 now to schedule our small business tax preparation services.</p>
      </section>
      <section className="trustusbiz">
        <h2>Precision in Numbers, Trust in Every Tax Detail.</h2>
        <p>When you hire us for small business tax preparation services, you expect superior customer service and reliability. At El Paso Business Solutions, we'll do our best to exceed your expectations. That's why our business tax preparation services will include:</p>
        <p> Detailed explanations of your current tax situation
            Personal advice to help you avoid future tax liabilities
            Qualified accountants who are ready to answer all your questions.</p>
            <p>Not only will our small business tax preparation services help you run a more successful business, but they'll also give you the opportunity to focus on other aspects of your daily operations. If you still have questions or concerns about our business tax preparation services, reach out to a member of our team today.</p>
      </section>
      <Footer/>
      <Right/>
      {/* Add content specific to Business Taxes */}
    </div>
  );
};

export default BusinessTaxes;
