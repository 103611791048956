import React from 'react';
import './Style2.css';
const ContactMap = () => {
  return (
    <div className="map-container">
      <iframe
        title="Google Map"
        width="500"
        height="320"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3392.107028276598!2d-106.36763198924389!3d31.767566673993773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e75b3c13eaaaab%3A0x8e79249c5f2578dc!2s9434%20Viscount%20Blvd%20suite%20102%2C%20El%20Paso%2C%20TX%2079925!5e0!3m2!1sen!2sus!4v1707683598004!5m2!1sen!2sus"
      ></iframe>
    </div>
  );
};

export default ContactMap;
