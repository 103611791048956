import React from 'react';
import FacebookImage from './FB.png'; // Adjust the path based on your file structure
import InstagramImage from './instagram2-logo.png'; // Adjust the path based on your file structure

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="logo-container">
        <img src="logo.jpg" alt="Logo" />
      </div>
      <div className="social-media">
        <a href="https://www.facebook.com/profile.php?id=61556720210491&mibextid=uzlsIk" target="_blank" rel="noopener noreferrer">
          <img className="facebook-icon" src={FacebookImage} alt="Facebook" />
        </a>
        <a href="https://www.instagram.com/elpasobusinesssolutions/" target="_blank" rel="noopener noreferrer">
          <img className="instagram-icon" src={InstagramImage} alt="Instagram" />
        </a>
      </div>
      <div className="contact-info">
        <p>9434 Viscount Blvd suite 102, El Paso, TX 79925</p>
        <p>Phone: (915) 231-6746</p>
        <p>Email: Contact@epbizsolutions.com</p>
      </div>
    </footer>
  );
};

export default Footer;
