// src/components/PersonalTaxes.js
import React from 'react';
import Header from './Header';
import Footer from './Footer'
import './Style2.css';
import Right from './Right';

const PersonalTaxes = () => {
  return (
    <div>
    
      
     <Header/>
     <section className="personal-taxes-section">
        <h2>Personal Tax Preparations</h2>
      
        <a href="ContactPage" >
        <button className="transparent-button">Learn More</button>
        </a>
      </section>
      <section className="taxp"> 
       <p>Our personalized tax preparation services ensure maximum returns through meticulous examination of deductions and exceptions tailored to your unique financial situation. We handle accurate filing for both state and federal taxes, providing expert advice and trustworthy solutions. Our dedicated team goes beyond paperwork to design customized tax plans aligned with your financial goals, empowering you with financial confidence. </p>
       </section>
      <section className="contact-section">
        <p>Schedule an appointment for personal tax preparation services today and see what your tax return will look like. Call 915-231-6746 now to get put on our schedule.</p>
      </section>
      <section className="trustus">
        <h2> Tax Solutions You Can Trust </h2>
        <p>Filing a tax return is a daunting task, but thankfully you don't have to do it alone. El Paso Business Solutions is here to help with our tax consultation services. You may feel intimidated working with a tax professional, but you won't have to worry about a thing. Our tax experts are:</p>
        <p> Highly trained and experienced
            Understanding and supportive
            Honest and professional</p>
            <p>When you hire us for personal tax preparation services, we'll put your needs first. You can count on us to answer your questions and listen to your concerns. Plus, we're always available to clarify anything you don't understand.
Choose our trusted team for your tax consultation services and personal tax preparation services today.
</p>
      </section>
     <Footer/>
     <Right/>
      {/* Add your content here */}
    </div>
  )
}

export default PersonalTaxes;
