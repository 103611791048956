// src/components/NotaryServices.js
import React from 'react';
import './Style2.css';
import Header from './Header';
import Footer from './Footer';
import Right from './Right';
const NotaryServices = () => {
  return (
    <div>
      <Header/>
      <section className="notary"> 
      <h2>Notary Services</h2>
        <a href="ContactPage" >
        <button className="transparent-button">Learn More</button>
        </a> 
      </section>
      <section className="note">
      <p> El Paso Business Solutions simplifies the notarization process for a variety of legal documents, offering reliable authentication services for power of attorney orders, medical documents, business agreements, real estate documents, bills of sales, and wills. When dealing with crucial legal paperwork, our local notary services provide the convenience of ensuring authenticity without the need for an extensive search. With a comprehensive network of notaries, we deliver top-notch services to clients in the El Paso, TX area, ensuring your documents are notarized efficiently and precisely, wherever and whenever you need them.</p>
      </section>
      <section className="contact-section">
        <p>Want to learn more about our document authentication process? Call 915-231-6746 today.</p>
      </section>
      <section className="notarybiz">
      <h2> Notarize with Confidence, Seal Your Success. </h2>
        <p>Are you looking for document authentication services in the El Paso, TX area? Consider turning to El Paso Business Solutions. Our local company is trusted by many clients because we are:</p>
        <p> Knowledgeable
            Convenient,
            Efficient,
            Friendly,
            Reliable, and
            Fast      </p>
            <p>Oftentimes, finding a notary public can take time out of your busy day - but not with us. You can trust our dependable team to quickly notarize anything from wills and medical documents to real estate papers and bills of sale.</p>
      </section>
      <Footer/>
    <Right/>
    </div>
  );
};

export default NotaryServices;
