import React from 'react';
import './Style2.css';
const Right = () => {
    return (  

        <div class="copyright-container">
        <p>&copy; 2024 El Paso Business Solutions, all rights reserved.</p>
        <p> Website designed by: paigeephlin@gmail.com</p>
      </div>
    

    );
};

export default Right;
