


import './Style.css'; // Import your CSS file



import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


const TaskbarComponent = () => {
  useEffect(() => {
    const taskbar = document.getElementById('taskbar');
    let prevScrollPos = window.pageYOffset;

    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        taskbar.style.top = '0';
      } else {
        taskbar.style.top = `-${taskbar.offsetHeight}px`;
      }
      prevScrollPos = currentScrollPos;
    };
  }, []);

  return (
    <div className="taskbar" id="taskbar">
      
      
     
      <ul className="options">
       <li> <Link to="/">Home</Link></li>
       <li> <Link to="/PersonalTaxes">Personal Taxes</Link></li>
       <li> <Link to="/BusinessTaxes">Business Taxes</Link></li>
        <li><Link to="/Incorporations">Incorporations</Link></li>
        <li><Link to="/NotaryServices">Notary Services</Link></li>
      </ul>
    </div>
  );
};

export default TaskbarComponent;
