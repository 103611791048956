// src/components/BusinessTaxes.js
import React from 'react';
import './Style2.css';
import Header from './Header';
import Footer from './Footer';
import ContactForm from './ContactForm';
import ContactMap from './ContactMap';
import Right from './Right';

const ContactPage = () => {
  return (
    <div>
    <Header/>
    <section className='Contact'> 
    <ContactForm/>
    <ContactMap/>
    </section>
    
    <Footer/> 
    <Right/>
      {/* Add content specific to Business Taxes */}
    </div>
  );
};

export default ContactPage;