import React from 'react';
import { Link } from 'react-router-dom';
import AboutImage from './About.png'; // Adjust the path based on your file structure

const YourComponent = () => {
  return (
    <section className="about-us-panel">
      <h3>Our Services</h3>
      <div className="about-us-content">
        <p className="about-us-text"></p>
        <div className="stars-container">
          <div className="star">
            <img src={AboutImage} alt="Star" />
            <li> <Link to="/PersonalTaxes">Personal Taxes</Link></li>
          </div>
          <div className="star">
            <img src={AboutImage} alt="Star" />
            <li> <Link to="/BusinessTaxes">Business Taxes</Link></li>
          </div>
          <div className="star">
            <img src={AboutImage} alt="Star" />
            <li> <Link to="/Incorporations">Corporate Taxes</Link></li>
          </div>
          <div className="star">
            <img src={AboutImage} alt="Star" />
            <li> <Link to="/NotaryServices">Notary Services</Link></li>
          </div>
        </div>
      </div>
    </section>
  );
};

export default YourComponent;

