import React from 'react';
import ReactDOM from 'react-dom/client';
import './Style.css';
import './index.css';
import PersonalTaxes from './PersonalTaxes';
import BusinessTaxes from './BusinessTaxes';
import Incorporations from './Incorporations';
import NotaryServices from './NotaryServices'
import ContactPage from './ContactPage';
import App from './App';
import reportWebVitals from './reportWebVitals';
/*import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";*/
import { createBrowserRouter, RouterProvider } from 'react-router-dom/dist';


const router =createBrowserRouter([{
  path: "/", 
  element: <App />
},
  {
    path: "PersonalTaxes", 
  element: <PersonalTaxes/>},
  {
    path: "BusinessTaxes", 
  element: <BusinessTaxes/>},
  {
    path: "Incorporations", 
  element: <Incorporations/>},
  {
    path: "NotaryServices", 
  element: <NotaryServices/>},

  {
    path: "ContactPage", 
  element: <ContactPage/>},
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
