// ContactForm.js


import emailjs from 'emailjs-com';

import './Style2.css';

import React, { useState } from 'react';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const sendEmail = async (e) => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_SERVICE_ID, process.env.REACT_TEMPLATE_ID, e.target, process.env.REACT_PUBLIC )

    // Check if all fields are filled out
    if (!name || !email || !message) {
      setErrorMessage('Please fill out all fields.');
      return;
    }

    // Assume you have a function (sendEmailFunction) to handle email sending
    try {
      // Call your sendEmailFunction with the necessary data
      // await sendEmailFunction({ name, email, message });

      // Reset form and show success message
      setName('');
      setEmail('');
      setMessage('');
      setSuccessMessage('Email sent successfully!');
      setErrorMessage('');
    } catch (error) {
      // Handle email sending failure
      setErrorMessage('Failed to send email. Please try again later.');
      setSuccessMessage('');
    }
  };

  return (
    <form className='ContactForm' onSubmit={sendEmail}>
      <label htmlFor="name">Your Name:</label>
      <input
        type="text"
        name="name"
        id="name"
        className="name_form"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <label htmlFor="email">Your Email:</label>
      <input
        type="text"
        name="email"
        id="email"
        className="email_form"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <label htmlFor="message">Message:</label>
      <textarea
        name="message"
        id="message"
        className="message_box"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <button className="button" type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;
